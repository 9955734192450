import React, { Component } from 'react';

class Bio extends Component {
    render = () => {
        return (
            <React.Fragment>
                <h1>A Brief Biography of Duncan Pitney and Description of His Art</h1>
                <p>J. Duncan Pitney was born in New York City in 1921 and died in Mendham, New Jersey in 2005. After the death of his father when he was six years old, his mother moved with her two sons to Aiken, South Carolina. The family spent their summers in Mendham, site of the Pitney family farm. His early schooling was at Aiken Preparatory School for boys, followed by five years at Milton Academy in Massachusetts. His days at Princeton University, from which he graduated in 1943, were compressed into three and a half years due to the onset of World War II. Following his wartime service in India flying aviation gasoline into China, he attended classes at the Art Students League in New York City. For the following thirty years, he painted in oils, solely for pleasure.</p>
                <p>After retirement in the late 1970’s, Pitney dramatically increased his output, and for the first time painted to show. His work had been extremely diverse in style, but now concentrated on natural subjects, viewed from close-up, placed on larger canvasses. Realism--in the form of people, landscapes and still lifes--gave way to his long preoccupation with the effect of light in the sky, on the water, and at the water’s edge. Of this he wrote: “This [large] group of paintings began with skies and clouds, moved on to sea and waves, and wound up with sand and water. A study of these elements, with a particular focus on light in and about them, was a natural visual sequence.” Pitney’s first two shows were at Findlay Galleries in New York City during 1981 and 1982. Sea-Clouds and then Selected Works featured large-canvas studies of clouds, breaking waves and the foam patterns on shallow water that they projected onto the sand.</p>
                <p>Soon afterward, at the urging of Richard Stone Reeves, the idea of painting horses next inspired Pitney’s imagination. The famous horse painter suggested that some Pitney paintings would be a welcome addition to the collection for his gallery in New York—The Racing Scene. Consequently, Pitney developed a new technique of painting solely figures (humans as well as horses) on untouched natural canvasses. His talent for drawing and modeling came to the fore, and this series of paintings found its way into collections such as those of Malcolm Forbes.</p>
                <p>Near the end of the 1980’s, Pitney shifted his focus back to close-up views of nature, this time of roses and leaves. These subjects were highlighted in his subsequent shows, including Leaves and Petals at the Frelinghuysen Arboretum in 1990 in Morristown. Later in the decade, in his final large scale productive phase, Pitney’s paintings of sea “calligraphy”—the foam designs floating just above the sand at the ocean’s edge—shifted from natural light to stylized abstract colors.</p>
                <p>Pitney’s stepson, Trygve B. Sletteland, has compiled The Paintings of J. Duncan Pitney, a photographic catalog of the artist’s life work. The Pitney Catalog’s final iteration presents photographs of the entirety of his 248 paintings, along with other data on each of them. His paintings are divided into four categories: the sea, clouds, the earth, and human life. These are further broken into 13 major divisions: (1) seascapes, waves, and “sea calligraphy”; (2/3) clouds, leaves, flowers, and landscapes; and (4) horse racing, urban scenes, still lifes, portraits, Caribbean natives, and miscellaneous/abstracts.</p>
                <p>There are only two copies of the Pitney Catalog in existence. The first resides permanently in the reference section of the Marquand Museum of Art &amp;Archaeology at Princeton University in Princeton, NJ, where it is available to the public. The 2 nd copy is in the possession of its author, who may be reached at P.O. Box 256, Laguna Beach, CA 92652. In the future, this family copy of the Pitney Catalog will be looked after by Alexis M. Donovan, who is now located in Denver, CO.</p>
                <p>Following compilation of the photographic catalog of Pitney’s complete work in 2005-6, Sletteland produced a book, “Sea—Clouds, The Art Legacy of J. Duncan Pitney” in 2008. The book contains high quality photographs of 69 of Pitney’s finest paintings, as well as a non-photographic catalog describing 244 of his 248 paintings. Five hundred copies were printed, with half of them distributed to American art museums, art libraries, and university art departments. This book was expressly produced not to be sold; however, some of the institutions to which it was donated gratis sold the book rather than curating it as intended and it can still be found for sale on amazon.com. Also, copies may still be available to the author’s family or close friends.</p>
            </React.Fragment>
        )
    }
}

export default Bio
