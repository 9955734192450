import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
} from "react-router-dom";
import Bio from "./pages/Bio";
import Book from "./pages/Book";
import Art from "./pages/Art";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="App">
    <Router>
        <header className="App-header">
            <h1>Pitney Art Legacy</h1>
            <nav>
                <ul>
                    <li>
                        <NavLink
                            isActive={(match, location) =>  match || location.pathname === '/' }
                            to="/bio">Bio</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active" to="/art">Art</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active" to="/book">Book</NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active" to="/contact">Contact</NavLink>
                    </li>
                </ul>
            </nav>
        </header>
        <Switch>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route path="/art">
                <Art />
            </Route>
            <Route path="/art/:id">
                <Art />
            </Route>
            <Route path="/book">
                <Book />
            </Route>
            <Route path="/bio">
                <Bio />
            </Route>
            <Route path="/">
                <Bio />
            </Route>
        </Switch>
    </Router>
    </div>
  );
}

export default App;
