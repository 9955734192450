import React, { Component } from 'react';

class Book extends Component {
    render = () => {
        return (
            <React.Fragment>
                <p>Pitney’s stepson, Trygve B. Sletteland, has compiled The Paintings of J. Duncan Pitney, a photographic catalog of the artist’s life work. The book presents photographs of 64 of JDP's paintings; 244 are listed and described in a non-illustrated catalog in an appendix. His paintings are divided into three categories: the sea, earth, and human life. These are further broken into 12 major divisions: (1) seascapes, waves, and “sea calligraphy”; (2) clouds, leaves, flowers, and landscapes; and (3) horse racing, urban scenes, still lifes, portraits, and Caribbean natives.</p>
                <p>Its availability will be updated here, and a watermarked PDF will soon be added.</p>
            </React.Fragment>
        )
    }
}

export default Book
