import React, { Component } from 'react';

class Book extends Component {
    render = () => {
        return (
            <address>
                <p>
                    Please Direct any questions or concerns to:<br />
                    Trygve Sletteland, P.O. Box 256, Laguna Beach, CA 92652
                </p>
                <p>
                    Website developed and maintained by <a href="mailto:ethan@slette.land">Ethan Sletteland</a><br />
                </p>
            </address>
        )
    }
}

export default Book
