import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import filenames from '../data/art.json';

class Art extends Component<any, any> {

    constructor(props: Object) {
        super(props);
        const serverPath = "images/"
        const initialTab = "sea"
        this.state = {
            serverPath,
            activeTab: initialTab,
            categories: [
                "sea",
                "flowers",
                "leaves",
                "other",
            ],
            images: this.prefixor(serverPath, initialTab).files
        };
    }

    prefixor = (serverPath: string, category: string): Filenames => {
         let r: Filenames = {
             files: [...filenames.files].filter((file) => file.category === category)
        };
         r.files.forEach((filename,i,a) => {
             filename.original = serverPath + category + "/" + filename.filename
             filename.thumbnail = serverPath + category + "/" + filename.filename
         })
        return r
    }

    selectCategory = (serverPath: string, category: string) => {
        const images: Filenames = this.prefixor(serverPath, category)
        this.setState({images: images.files, activeTab: category});
    }

    componentDidMount() {
    }

    render = () => {
        return (
            <React.Fragment>
                <nav className={"tabs"}>
                    <ul>
                        {this.state.categories.map((cat: string, index: number) => {
                            return (<li key={index}><button
                                style={{"textTransform": "capitalize"}}
                                className={(this.state.activeTab === cat) ? "active" : ""}
                                onClick={event => this.selectCategory(this.state.serverPath,cat)}>{
                                    (cat === "sea") ? "Sea and Clouds" : cat
                                }</button></li>)
                        })}
                    </ul>

                </nav>
                <ImageGallery items={this.state.images} />
            </React.Fragment>
        )
    }
}

interface Filename {
    filename: string,
    original?: string,
    thumbnail?: string,
    category: string
}

interface Filenames {
    files: Filename[]
}

export default Art
